.description {
    position: absolute;
    left: 50px;
    top: 50px;
    opacity: 0;
    transform: translate(0, -100%);
    transition: transform 1.5s ease-in-out, opacity 2s ease-in-out;
}

.description-show {
    transform: translate(0);
    opacity: 1;
}

h1 {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 60px;
}

#intro {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 30px;
}

#location {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 25px;
    margin: auto;
    margin-left: 10px;
}

.location {
    margin-left: 240px;
    margin-top: -30px;
    margin-bottom: 70px;
    display: flex;
    align-content: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 8.5vw;
        margin: auto;
    }

    #intro {
        font-size: 6vw;
        margin-left: 20px;
    }

    #location {
        font-size: 5vw;
    }

    .description {
        left: 10px;
        top: 10px;
    }

    .location {
        margin: auto;
        margin-bottom: 70px;
    }
    .location img {
        height: 8vw;
        width: 8vw;
    }
}
/* roboto-mono-regular - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto Mono'),
        url('../fonts/roboto-mono-v13-latin-regular.woff2') format('woff2'),
        url('../fonts/roboto-mono-v13-latin-regular.woff') format('woff');
}
body {
    margin: 0;
    padding: 0;
    background-color: #0f0800;
}
p {
    color: #423d17;
}
.canvas {
    display: block;
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}
.projects {
    display: block;
    padding-top: 100vh;
    width: 100vw;
    height: 100vh;
}
.code {
    white-space: pre-wrap;
    padding: 10;
    overflow: hidden;
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
}

.animate-code-0 {
    position: fixed;
    animation: slideDown 25s linear infinite;
}
.animate-code-1 {
    position: fixed;
    animation: slideDown 19s linear infinite;
}

.gap {
    height: 100vh;
}

.upside-down {
    position: absolute;
    height: 100vh;
    top: 0;
    bottom: 0;
    transform: rotate(180deg);
    width: 100vw;
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

@keyframes slideDown {
    100% {
        transform: translateY(-100%);
    }
}
.scroll-down-arrow {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
    background-size: contain;
    background-repeat: no-repeat;
}

.scroll-down-link {
    height: 60px;
    width: 80px;
    margin: 0px 0 0 -40px;
    line-height: 60px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    color: #b6b42d;
    text-align: center;
    font-size: 70px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    opacity: 0;
    animation: fade_move_down 2s ease-in-out infinite;
}

#zoomout {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    position: absolute;
    left: calc(50% - 60px);
    bottom: 50px;
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 2s ease-in-out;
}

/*animated scroll arrow animation*/
@keyframes fade_move_down {
    0% {
        transform: translate(0, -20px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(0, 20px);
        opacity: 0;
    }
}

.zoomout-show {
    opacity: 1;
    transform: translate(0, -50%);
}

@media screen and (min-width: 600px) {
    #zoomout {
        bottom: -10%;
    }
}

@media screen and (max-width: 600px) {
    .scroll-down-link {
        bottom: 10%;
    }
    .scroll-down-arrow {
        opacity: 0;
        bottom: -10%;
    }
}
.sidebar {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(10%, -50%);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 1s ease-in-out;
}

.sidebar-show {
    opacity: 1;
    transform: translate(0, -50%);
}

a:link {
    color: #b6b42d;
}

a:visited {
    color: #b6b311;
}

a:hover {
    color: #d6d333;
}

a:active {
    color: #b6b42d;
}

.side-btn {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 50px;
    margin: auto;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 25px;
}

.sidebar-btn:hover {
    transform: translate(0, 0);
}

.sidebar-btn-github:hover {
    transform: translate(6%, 0) !important;
}

.sidebar-btn-about:hover {
    transform: translate(45%, 0) !important;
}

.sidebar-btn {
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    transform: translate(85%, 0);
    transition: transform 0.4s;
}

.sidebar-btn img {
    filter: drop-shadow(0 0 0.75rem black);
}

@media screen and (max-width: 600px) {
    .sidebar-btn img {
        height: 15vw;
        width: 15vw;
    }

    .sidebar-btn {
        transform: translate(80%, 0);
    }

    .side-btn {
        font-size: 7vw;
        margin: auto;
        margin-left: 0;
        padding-left: 10px;
        padding-right: 25px;
    }
}
